<template>
  <div class="DevWeb" >
    <section id="dev-web-main-section">
      <div class="container">
        <div class="row section-title-margin">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title">
              Développement web et application
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12 ">
            <p class="section-slogan">
              Notre objectif : que vous puissiez tester votre site/application moins d'une semaine
              après sa commande.
            </p>
            <p class="section-explanation">
              Pour cela, nous allions des compétences de conception, de
              développement (web, mobile, applicatif) et de déploiement pour vous accompagner
              tout au long de la construction du produit.<br>
              Notre équipe vous permet de concevoir rapidement des plateformes et applications
              complexes à destination de vos équipes internes ou de vos clients.
              Nous vous permettons également de récolter et d'héberger des données personnelles et/ou de santé.
            </p>
          </div>
        </div>
      </div>
    </section>


    <section id="dev-web-methodology-section">
      <div class="container">
        <div class="row section-title-margin">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title">
              Notre méthodologie
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="row basic-alt-tabs text-explanation-on-white">
              <div class="col-12">
                <div class="alt-tabs-icon">
                  <b-icon-stopwatch></b-icon-stopwatch>
                </div>
                Sprint de conception
              </div>
            </div>
            <div class="row section-explanation text-explanation-on-white" style="margin-top: 20px;">
              <div class="col-12">
                Nous entrons dans une phase de conception immédiate,
                sans perte d’énergie en intermédiation et formalisation superflue.<br>
                Un échange, une proposition, un accord et nous commençons à développer.
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6 mt-4 mt-lg-0">
            <div class="row basic-alt-tabs text-explanation-on-white">
              <div class="col-12">
                <div class="alt-tabs-icon">
                  <b-icon-code></b-icon-code>
                </div>
                Développement
              </div>
            </div>
            <div class="row section-explanation text-explanation-on-white" style="margin-top: 20px;">
              <div class="col-12">
                Nous rendons obsolète la notion de « temps projet » et
                privilégions une exécution rapide et une première version mise
                à votre disposition en une semaine.
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6 mt-4">
            <div class="row basic-alt-tabs text-explanation-on-white">
              <div class="col-12">
                <div class="alt-tabs-icon">
                  <b-icon-arrow-repeat></b-icon-arrow-repeat>
                </div>
                Phase d'itération
              </div>
            </div>
            <div class="row section-explanation text-explanation-on-white" style="margin-top: 20px;">
              <div class="col-12">
                Sur la base de la première version mise à votre disposition,
                nous itérons et engageons un suivi journalier avec vous pour
                répondre le plus précisément à vos besoins.
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6 mt-4">
            <div class="row basic-alt-tabs text-explanation-on-white">
              <div class="col-12">
                <div class="alt-tabs-icon">
                  <b-icon-bullseye></b-icon-bullseye>
                </div>
                Déploiement continu
              </div>
            </div>
            <div class="row section-explanation text-explanation-on-white" style="margin-top: 20px;">
              <div class="col-12">
                Après avoir réalisé une phase d’audit de sécurité et de tests de charge, nous déployons votre
                application sur des serveurs dédiés selon le type de données hébergées
                (données de santé, etc.), le nombre d'utilisateurs attendus et la puissance nécessaire à votre
                application.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section id="dev-web-technologies-section">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title section-title-margin">
              Nos technologies et standards de qualité
            </h3>
          </div>
        </div>

        <div class="row basic-alt-tabs text-explanation-on-black">
          <div class="col-lg-3 text-center align-self-center">
            Simple et évolutif
          </div>
          <div class="col-lg-3 text-center align-self-center">
            Sécurisé
          </div>
          <div class="col-lg-3 text-center align-self-center">
            Stable
          </div>
          <div class="col-lg-3 text-center align-self-center">
            Rapide et scalable
          </div>
        </div>
        <hr style="background-color:#D8D8D8; margin-top: 70px;" width="30%">
        <div class="row" style="margin-top: 70px;">
          <div class="col-lg-2 offset-lg-1 col-sm-6 col-12 text-center  align-self-center">
            <img class="img-fluid" :src="require('@/static/images/python_django.png')">
          </div>
          <div class="col-lg-2 mt-lg-0 col-sm-6 col-12 mt-4 text-center align-self-center">
            <img class="img-fluid" :src="require('@/static/images/vue_nuxt.png')">
          </div>
          <div class="col-lg-2 mt-lg-0 col-sm-6 col-12 mt-4 text-center align-self-center">
            <img class="img-fluid" :src="require('@/static/images/nginx_uwsgi.png')">
          </div>
          <div class="col-lg-2 mt-lg-0 col-sm-6 col-12 mt-4 text-center align-self-center">
            <img class="img-fluid" :src="require('@/static/images/sonarqubes.png')">
          </div>
          <div class="col-lg-2 mt-lg-0 col-12 mt-4 text-center align-self-center">
            <img class="img-fluid" :src="require('@/static/images/sentry.png')">
          </div>
        </div>
      </div>
    </section>

    <section id="dev-web-launch-project-section">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title section-title-margin">
              Lancez votre projet
            </h3>
          </div>
        </div>

        <div class="row basic-alt-tabs text-explanation-on-black">
          <div class="col-12 text-center">
            <b-button to='/contact' class="p-3" variant="outline-dark" size='lg'>
              Nous contacter
            </b-button>
          </div>
        </div>
      </div>
    </section>

    <footerComponent />
  </div>
</template>

<script>
const footerComponent = () => import('@/components/footerComponent')


export default {
  name: 'DevWeb',
  components: {
    footerComponent,
  },
}
</script>

<style>
#dev-web-main-section {
  padding-top: 20px;
  padding-bottom: 80px;
  background-color: black;
  color: white;
}

#dev-web-technologies-section {
  margin-top: 25px;
  padding-bottom: 70px;
  background-color: black;
  color: white;
}

#dev-web-launch-project-section {
  padding-bottom: 80px;
}


</style>
